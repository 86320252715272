@import "@Static/css/common";

$color1: getColorValue(1, (56, 36, 242), 0.2);
$color2: getColorValue(1, (56, 36, 242), 0.4);

.root {
    background-color: $color1;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.container {
    position: relative;
    margin-bottom: 3rem;
}

.title {
    text-align: center;
}

.dotMatrix {
    circle {
        fill: $color2;
    }
    position: absolute;
    top: 3rem;
}

@media only screen and (min-width: 901px) {
    .dotMatrix {
        right: calc(((100% - 800px) / 2) - 2rem);
    }
}

@media only screen and (max-width: 900px) {
    .dotMatrix {
        right: -6px;
    }
}

.dotListClass {
    bottom: -3rem;
}
