@import "@Static/css/common";

$color1: getColorValue(1, (56, 36, 242));
$color2: getColorValue(2, (255, 255, 255));
$color3: getColorValue(3, (51, 51, 51));

$cardBgColor: rgb(
    var(--template-page-card-background-host-theme, (255, 255, 25))
);


.root {
    display: flex;
    justify-content: center;
    height: 100%;
    font-size: 0.8rem;
}

.container {
    background-color: $cardBgColor;
    height: 100%;
    width: 100%;
    max-width: 800px;
    padding: 1rem;
}

.quoteContainer {
    display: flex;
    margin-bottom: 1rem;
}

.quote {
    path {
        fill: $color1;
    }
}

.contentContainer {
    position: relative;
    height: 200px;
    white-space: pre-wrap;
    word-break: break-word;
}

.videoContainer {
    iframe {
        border-radius: 4px;
    }
}

.imageContainer {
    display: flex;
    justify-content: center;
    background: linear-gradient(
        to right,
        $color1,
        $color2,
    );
    .image {
        width: 50%;
        height: 100%;
        border-radius: 4px;
        object-fit: cover;
    }
}

.authorContainer {
    display: flex;
    align-items: center;
}

.authorName {
    margin-left: 10px;
}

.readMore {
    padding: 0;
}

.mediaOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
