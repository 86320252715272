@import "@Static/css/common";

// theme colours
$color1: getColorValue(1, (56, 36, 242));
$color2: getColorValue(2, (255, 255, 255));
$color3: getColorValue(3, (51, 51, 51));
$color4: getColorValue(1, (56, 36, 242), 0.06);

.container {
    background-color: $color2;
    margin-top: var(--host-page-navbar-height, 54px);
}

.bannerContainer {
    position: relative;
    margin-bottom: 1rem;
    height: #{(2241 / 4320 * 100) + "vw"};
    min-height: 220px;
    max-height: 900px;
}
.title {
    margin: 0 0 0.5rem 0;
}

.coverImage {
    width: 100%;
    height: #{(2241 / 4320 * 100) + "vw"};
    min-height: 220px;
    max-height: 900px;
    object-fit: cover;
    filter: brightness(0.8);
    background-color: #eee;
}

.profileImg {
    background-color: #fcfcfc;
    width: 100%;
    max-width: 500px;
    margin: auto;
}

.clip {
    width: 100%;
    height: 30%;
    clip-path: polygon(0 100%, 0 0, 70% 80%, 100% 0, 100% 100%);
    position: absolute;
    bottom: -12px;
}

.clipWhite {
    @extend .clip;
    background-color: $color2;
}

.clipColoured {
    @extend .clip;
    background-color: $color4;
    z-index: 1;
}

.bannerTextContainer {
    position: absolute;
    height: 60%;
    top: 20%;
    left: 0;
    max-width: 600px;
    color: $color2;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media only screen and (max-width: 800px) {
    .bannerTextContainer {
        top: 0;
        height: 75%;
    }
}

.shortDescription {
    margin-bottom: 1rem;
    &.desktopOnly {
        display: initial;
    }
    &.mobileOnly {
        display: none;
    }
}

@media only screen and (max-width: 800px) {
    .shortDescription {
        margin-bottom: 0.5rem;
        font-size: 18px;
        margin: 8px auto 12px;
        &.desktopOnly {
            display: none;
        }
        &.mobileOnly {
            display: initial;
        }
    }
}

.getInTouchButtonContainer {
    display: flex;
    align-items: flex-start;
}

@media only screen and (max-width: 800px) {
    .getInTouchButtonContainer {
        flex-direction: column;
        display: none;
    }
}

.getInTouchButton {
    padding: 10px 24px;
    margin-right: 1rem;
    background-color: $color1;
    color: $color2;
}

@media only screen and (max-width: 800px) {
    .getInTouchButton {
        padding: 8px 12px;
        width: 100%;
    }
    .arrowIcon {
        display: none;
    }
}

.aboutCreatorContainer {
    display: flex;
}

@media only screen and (max-width: 800px) {
    .aboutCreatorContainer {
        flex-direction: column;
    }
}

.profileImgContainer {
    position: relative;
}

.profileImgTextContainer {
    position: absolute;
    background-color: $color1;
    color: $color2;
    width: 100px;
    height: 100px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    line-height: 1;

    &::after {
        top: 20px;
        right: 0;
        width: 90%;
        height: 10%;
        content: "";
        position: absolute;
        background-color: $color2;
    }
}

@media only screen and (min-width: 801px) {
    .profileImgTextContainer {
        top: 200px;
        left: -1rem;
        transform: translateY(-50%);
    }
}

@media only screen and (max-width: 800px) and (min-width: 441px) {
    .profileImgContainer {
        text-align: center;
    }
    .profileImgTextContainer {
        top: 200px;
        left: -0.5rem;
        transform: translateY(-50%);
    }
}

@media only screen and (max-width: 440px) {
    .profileImgContainer {
        margin-bottom: 1rem;
        text-align: center;
    }

    .profileImgTextContainer {
        left: 50%;
        bottom: -0.5rem;
        transform: translateX(-50%);
    }
}

.profileImgBigText {
    flex-grow: 1;
    width: min-content;
    z-index: 1;
    background-color: $color1;
    padding-right: 4px;
    letter-spacing: -0.5px;
}

.profileImgText {
    font-size: 0.8rem;
}

@media only screen and (min-width: 800px) {
    .aboutCreatorTextContainer {
        width: calc(100% - 400px);
        margin-left: 2rem;
    }
}
